const config = {
   providers: [
      "amazon",
      "facebook",
      "github",
      "google",
      "instagram",
      "linkedin"
   ]
};

export default config;
