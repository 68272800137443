import { useState } from "react";
import { Button, Card } from "antd";
import { RollbackOutlined } from "@ant-design/icons";

import GoogleOtp from "./GoogleOtp";
import Login from "./Login";
import Otp from "./Otp";

const Auth = ({ history, ...props }) => {
    const [page, setPage] = useState("login");
    const [qrCode, setQRCode] = useState("");

    const changePage = page => {
        setPage(page);
    };

    const changeQRCode = qrcode => {
        setQRCode(qrcode);
    };

    if (page == "login") {
        return (
            <Card
                title={`LOGIN ADMIN ${window.sparrowConfig.app.name}`}
                className="loginPageContainer"
                extra={
                    <a target="_blank" href="https://urekamedia.com">
                        <img src={`${uploadURL}/logo-u.png`} alt="UrekaMedia" style={{maxHeight: '20px'}} />
                    </a>
                }
                style={{padding: '5px 0'}}
            >
                <Login
                    changePage={changePage}
                    changeQRCode={changeQRCode}
                />
            </Card>
        );
    } else if (page == "verify_google_otp") {
        return (
            <>
                <Card
                    title={window.sparrowConfig.app.name}
                    className="OTPFormContainer"
                    extra={
                        <Button
                            type="primary"
                            icon={
                                <RollbackOutlined
                                    onClick={() => changePage("login")}
                                />
                            }
                        />
                    }
                >
                    <GoogleOtp qrCode={qrCode} />
                </Card>
            </>
        );
    } else if (page == "verify_otp") {
        return (
            <>
                <Card
                    title={window.sparrowConfig.app.name}
                    className="OTPFormContainer"
                    extra={
                        <Button
                            type="primary"
                            icon={
                                <RollbackOutlined
                                    onClick={() => changePage("login")}
                                />
                            }
                        />
                    }
                >
                    <Otp />
                </Card>
            </>
        );
    }
};
export default Auth;
