import React, { createContext, useReducer } from "react";
import { initialState, AuthReducer } from "../Reducers/AuthReducer";
import { SET_CONFIG, SET_LOCATE } from "../Dispatch/type";
import { message } from "antd";
import api from "../../helpers/api";
export const AuthContext = createContext();
const AuthContextProvider = ({ children, axios, history }) => {
    const [data, dispatch] = useReducer(AuthReducer, initialState);

    const getConfig = () => {
        return api
            .getSecured()
            .get(`/get-config`)
            .then(res => {
                let { language, config, login_with_account } = res.data;
                dispatch({
                    type: SET_CONFIG,
                    payload: {
                        config,
                        language,
                        login_with_account
                    }
                });
            }).catch(error => {
                console.log(error);
            });
    };

    const Login = async values => {
        try {
            let email = values.email || "";
            let password = values.password || "";
            let remember = values.remember || false;
            const { data } = await api.getSecured().post(`/login`, {
                email,
                password,
                remember
            });
            return {
                error: null,
                ...data
            };
        } catch (err) {
            return {
                error: err.message
            };
        }
    };

    const SocialLogin = async response => {
        try {
            let provider = response.provider || "";
            let profile = response.profile || {};
            let token = response.token || {};
            const { data } = await api.getSecured().post("/socialLogin", {
                provider,
                profile,
                token
            });
            return {
                error: null,
                ...data
            };
        } catch (err) {
            return {
                error: err.message
            };
        }
    };

    const VerifyOtp = async values => {
        try {
            let code = values.code;
            const { data } = await api.getSecured().post("/verify", {
                code
            });
            return {
                error: null,
                ...data
            };
        } catch (err) {
            return {
                error: err.message
            };
        }
    };

    const setLocale = values => {
        return api
            .getSecured()
            .post(`/change-locate`, values)
            .then(res => {
                let { status } = res.data;
                if (status) {
                    dispatch({
                        type: SET_LOCATE,
                        payload: values
                    });
                }
            });
    };

    const todoContextData = {
        data,
        Login,
        SocialLogin,
        VerifyOtp,
        getConfig,
        setLocale,
        dispatch
    };
    return (
        <AuthContext.Provider value={todoContextData}>
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContextProvider;
