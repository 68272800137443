import { Fragment, useState, useContext } from "react";
import { Form, Input, Button, Alert, Spin, Row, Col, Card, Avatar } from "antd";
import { AuthContext } from "../Contexts/AuthContext";
import { useTranslation } from "react-i18next";
const { Meta } = Card;
const GoogleOtp = ({ history, ...props }) => {
    const { VerifyOtp } = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const { t } = useTranslation("auth");
    /**
     * @author huyvh19@gmail.com
     * @param {*} values
     */
    const verify = async values => {
        setError(null);
        setLoadingStatus(true);
        try {
            let data = await VerifyOtp(values);
            setLoadingStatus(false);
            if (data.error != null) {
                setError(data.error);
            }
            if (data.redirect_to) {
                window.location.replace(data.redirect_to);
            }
        } catch (err) {
            setLoadingStatus(false);
            setError(err.message);
        }
    };

    const onFinishFailed = errorInfo => {
        //console.log("Failed:", errorInfo);
    };

    return (
        <Fragment>
            {error && <Alert message={error} type="error" closable />}
            <br />
            <Form
                onFinish={verify}
                onFinishFailed={onFinishFailed}
                name="otp-form"
                className="login-form"
            >
                <Row justify="center" align="middle" type="flex">
                    <Col>
                        {props.qrCode ? (
                            <>
                                <Form.Item>
                                    <h2>{t("label.scan_qrcode")}</h2>
                                </Form.Item>
                                <div className="text-center">
                                    <img src={props.qrCode} />
                                </div>
                            </>
                        ) : (
                            <Form.Item>
                                <h2>{t("label.enter_otp")}</h2>
                            </Form.Item>
                        )}
                    </Col>
                </Row>
                <Form.Item
                    name="code"
                    className="OTPCodeContainer"
                    rules={[
                        {
                            required: true,
                            message: t("required.otp_required")
                        }
                    ]}
                >
                    <Input
                        size="large"
                        id="otp"
                        placeholder="XXXXXX"
                        type="text"
                        bordered={false}
                        maxLength={6}
                    />
                </Form.Item>
                <Form.Item className="centerBtnHolder">
                    {loadingStatus == true ? (
                        <div style={{ textAlign: "center" }}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <Button type="primary" htmlType="submit">
                            {t("label.validate_otp")}
                        </Button>
                    )}
                </Form.Item>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                            <Card
                                hoverable
                                style={{ width: '100%' }}
                            >
                                <Meta 
                                    avatar={<Avatar src="/logo/Authenticator.svg" />}
                                    title="IOS" 
                                    description="Authenticator" 
                                />
                            </Card>
                        </a>
                    </Col>
                    <Col span={12}>
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                            <Card
                                hoverable
                                style={{ width: '100%' }}
                            >
                                <Meta 
                                    avatar={<Avatar src="/logo/Authenticator.svg" />}
                                    title="Android" 
                                    description="Authenticator" 
                                />
                            </Card>
                        </a>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    );
};
export default GoogleOtp;
