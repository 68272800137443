import { Fragment, useContext } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import queryString from "query-string";
import Auth from "../components/Pages/Auth";
import { Row, Col } from "antd";
import { AuthContext } from "../components/Contexts/AuthContext";
import MySelect from "../components/Fields/MySelect";

const addLocationQuery = history => {
    history.location = Object.assign(history.location, {
        query: queryString.parse(history.location.search)
    });
};

const history = createBrowserHistory({ basename: "/" });
addLocationQuery(history);
history.listen(() => {
    addLocationQuery(history);
    if (window.SEND_USAGE_STATS) {
    }
});

export default () => {
    const { data, setLocale } = useContext(AuthContext);
    //get from data
    const { language, user } = data;

    const languageOnChange = value => {
        setLocale({
            locale: value
        });
    };

    return (
        <Fragment>
            <Router history={history}>
                <div className="login-view">
                    <div className="box-language">
                        <MySelect
                            options={language.locales}
                            value={language.locale}
                            width={100}
                            setFieldValue={languageOnChange}
                        />
                    </div>
                    <Row gutter={16} justify="center" align="middle">
                        <Col
                            className="gutter-row"
                            span={24}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={10}
                            xl={8}
                        >
                            <Switch>
                                <Route
                                    exact
                                    path={
                                        window.sparrowConfig.app.adminPrefix +
                                        "/auth/login"
                                    }
                                    render={props => <Auth {...props} />}
                                />
                            </Switch>
                        </Col>
                    </Row>
                </div>
            </Router>
        </Fragment>
    );
};
