import { Fragment, useContext, useState } from "react";
import { AuthContext } from "../Contexts/AuthContext";
import SocialButton from "../socialButton";
import { Form, Input, Button, Checkbox, Alert, Row, Spin, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Login = ({ history, ...props }) => {
    const { data, Login, SocialLogin } = useContext(AuthContext);
    const { config,  loginWithAccount, loadingConfig } = data;
    const [error, setError] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(false);

    // console.log({loadingConfig, loginWithAccount})

    const { t } = useTranslation("auth");
    /**
     * @author huyvh19@gmail.com
     * @param {*} values
     */
    const login = async values => {
        setError(null);
        setLoadingStatus(true);
        let data = await Login(values);
        setLoadingStatus(false);
        if (data.error != null) {
            setError(data.error);
        }
        if (data.redirect_to) {
            window.location.replace(data.redirect_to);
        }
        if (data.next_step != null) {
            const { qr_code } = data;
            props.changeQRCode(qr_code);
            props.changePage(data.next_step);
        }
    };
    /**
     * @author huyvh19@gmail.com
     * @param {*} response
     */
    const onLoginSocialSuccess = async response => {
        setError(null);
        let data = await SocialLogin(response);
        if (data.error != null) {
            setError(data.error);
        }
        if (data.redirect_to) {
            window.location.replace(data.redirect_to);
        }
        if (data.next_step != null) {
            const { qr_code } = data;
            props.changeQRCode(qr_code);
            props.changePage(data.next_step);
        }
    };

    /**
     * @author huyvh19@gmail.com
     * @param {*} err
     */
    const onLoginSocialFailure = err => {
        if (typeof err == "string") {
            setError(err);
        }
    };

    /**
     *
     * @param {*} provider
     * @param {*} node
     */
    const setNodeRef = (provider, node) => {
    };

    const onFinishFailed = errorInfo => {
        //console.log("Failed:", errorInfo);
    };

    return (
        <Spin spinning={loadingConfig} size="large">
            {error && <Alert message={error} type="error" closable />}
            <br />
            {loginWithAccount &&
                <>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: false
                        }}
                        onFinish={login}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: t("validation.email_required")
                                }
                            ]}
                        >
                            <Input
                                prefix={ <UserOutlined className="site-form-item-icon" /> }
                                placeholder={t("placeholder.email")}
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: t("validation.password_required")
                                }
                            ]}
                        >
                            <Input.Password
                                prefix={
                                    <LockOutlined className="site-form-item-icon" />
                                }
                                placeholder={t("placeholder.password")}
                            />
                        </Form.Item>
                        <Form.Item className="inline-flex">
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>{t("label.remember_me")}</Checkbox>
                            </Form.Item>
                        </Form.Item>
                        <Row justify="center">
                            <Form.Item>
                                {loadingStatus == true ? (
                                    <div style={{ textAlign: "center" }}>
                                        <Spin size="large" />
                                    </div>
                                ) : (
                                    <Button
                                        className="btn-login-custom"
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t("label.login")}
                                    </Button>
                                )}
                            </Form.Item>
                        </Row>
                    </Form>
                    <div
                        style={{
                            textAlign: "center",
                            paddingBottom: "5px",
                            fontSize: "16px"
                        }}
                    >
                        {window.sparrowConfig.services.google.client_id && (
                            <>
                                {window.sparrowConfig.lang.auth.or}
                            </>
                        )}
                    </div>
                </>
            }
            <Row justify="center" align="middle" type="flex">
                <Col>
                    <Form.Item className="otherMethodofForSignIn">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button colorGG"
                        >
                            {window.sparrowConfig.services.google.client_id && (
                                <SocialButton
                                    provider="google"
                                    appId={
                                        window.sparrowConfig.services.google.client_id
                                    }
                                    onLoginSuccess={onLoginSocialSuccess}
                                    onLoginFailure={onLoginSocialFailure}
                                    getInstance={setNodeRef.bind(
                                        this,
                                        "google"
                                    )}
                                    key={"google"}
                                    className="btn btn-block colorGG"
                                >
                                    <i className="fab fa-google-plus mr-2"></i>
                                    {
                                        window.sparrowConfig.lang.auth.sign_in_using_google
                                    }
                                </SocialButton>
                            )}
                        </Button>
                        <br/>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button colorFB"
                        >
                            {window.sparrowConfig.services.facebook.client_id && (
                                <SocialButton
                                    provider="facebook"
                                    appId={
                                        window.sparrowConfig.services.google.client_id
                                    }
                                    onLoginSuccess={onLoginSocialSuccess}
                                    onLoginFailure={onLoginSocialFailure}
                                    getInstance={setNodeRef.bind(
                                        this,
                                        "google"
                                    )}
                                    key={"facebook"}
                                    className="btn btn-block btn-primary"
                                >
                                    <i className="fab fa-facebook mr-2"></i>
                                    {
                                        window.sparrowConfig.lang.auth.sign_in_using_facebook
                                    }
                                </SocialButton>
                            )}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Spin>
    );
};

export default Login;
