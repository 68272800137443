import Promise from 'bluebird'

import { rslError } from '../utils'
import jwt_decode from "jwt-decode";

/**
 * Loads Google SDK.
 * @see https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiclientloadname--------version--------callback
 * @see https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2initparams
 * @see https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2getauthinstance
 */
// let client = null;
const load = ({ appId, scope }) => new Promise((resolve, reject) => {
  const firstJS = document.getElementsByTagName('script')[0]
  const js = document.createElement('script')

  js.src = 'https://accounts.google.com/gsi/client';
  js.async = true;
  js.setAttribute('defer', '');
  
  js.onload = () => {
    resolve();
  }
  if (!firstJS) {
    document.appendChild(js)
  } else {
    firstJS.parentNode.appendChild(js)
  }
})

const checkLogin = (status, accessToken) => new Promise((resolve, reject) => {
  const responsePayload = jwt_decode(accessToken);
  if(responsePayload.sub != undefined){
      return resolve({
        ...responsePayload,
        token: responsePayload
      })
  }else{
       return reject(rslError({
          provider: 'google',
          type: 'check_login',
          description: 'Not authenticated',
          error: null
      }))
  }
})

const login = (appId) => new Promise((resolve, reject) => {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: appId,
      scope: 'profile email',
      callback: (response) => {
        if (response && response.access_token) {
          resolve(response)
        }else{
          reject(rslError({
                provider: 'google',
                type: 'auth',
                description: 'Authentication failed',
                error: err
          }));
        }
      },
    });
    client.requestAccessToken();
})

/**
 * Trigger Google logout.
 * Requires SDK to be loaded first.
 * @see https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleauthsignout
 */
const logout = () => new Promise((resolve, reject) => {
  const GoogleAuth = window.gapi.auth2.getAuthInstance()
  GoogleAuth.signOut().then(resolve, reject)
})

/**
 * Helper to generate user account data.
 * @param {Object} response
 * @see https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetbasicprofile
 * @see https://developers.google.com/api-client-library/javascript/reference/referencedocs#googleusergetauthresponseincludeauthorizationdata
 */
const generateUser = (response) => {
  return {
    profile: {
    },
    token: {
      accessToken: response.access_token,
      scope: response.scope,
      expiresIn: response.expires_in,
    }
  }
}

const oldLoad = (appId, cid, fn, err) => {
  const js = document.createElement('script')

  js.src = 'https://apis.google.com/js/platform.js'
  js.id = 'gapi-client'

  js.onload = () => {
    window.gapi.load('auth2', () => {
      if (!window.gapi.auth2.getAuthInstance()) {
        window.gapi.auth2.init({
          client_id: appId
        })
      }

      window.gapi.auth2.getAuthInstance().attachClickHandler(cid, {}, fn, err)
    })
  }

  if (document.getElementsByTagName('script').length === 0) {
    document.appendChild(js)
  } else {
    document.getElementsByTagName('script')[0].parentNode.appendChild(js)
  }
}

export default {
  checkLogin,
  generateUser,
  load,
  login,
  logout,
  oldLoad
}
