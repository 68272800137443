require("./bootstrap");
import React from "expose-loader?React!react";
import ReactDOM from "expose-loader?ReactDOM!react-dom";
import AuthContextProvider from "./components/Contexts/AuthContext";
import App from "./components/App";
import { I18nextProvider } from "react-i18next";
import { Suspense } from "react";
import i18n from "./helpers/i18n";
import Loading from "./components/Loading/Loading";
/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//require('./app');

if (document.getElementById("root")) {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <AuthContextProvider history={history}>
                <Suspense fallback={<Loading />}>
                    <App history={history} />
                </Suspense>
            </AuthContextProvider>
        </I18nextProvider>,
        document.getElementById("root")
    );
}
