import amazon from './amazon'
import facebook from './facebook'
// import github from './github'
import google from './google'
// import instagram from './instagram'
import linkedin from './linkedin'

export default {
  amazon,
  // github,
  google,
  facebook,
  // instagram,
  linkedin
}
